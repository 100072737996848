<template>
    <div class="body">
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="address-wrapper" v-for="(items, index) in listLoadingList" :key="index">
                <div class="address-top-wrapper" @click="selectAddress(items.id)">
                    <div class="address-user-info">
                        <p>{{items.name}}</p>
                        <p>{{items.mobile}}</p>
                    </div>
                    <div class="address-info">{{items.province}} {{items.city}} {{items.district}}</div>
                    <div class="address-info">{{items.detail}}</div>
                </div>
                <div class="address-bottom-wrapper">
                    <div class="address-bottom-left-wrapper" v-if="items.default">
                        <p class="address-checked"></p>
                        <p>默认地址</p>
                    </div>
                    <div class="address-bottom-left-wrapper" v-else @click="setDefault(items.id)">
                        <p class="address-no-checked"></p>
                        <p>设为默认</p>
                    </div>
                    <div class="address-bottom-right-wrapper">
                        <div class="address-bottom-button" @click="editAddress(items.id)"><img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-repair.png"><p>编辑</p></div>
                        <div class="address-bottom-button" @click="delAddress(items.id)"><img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-delete.png"><p>删除</p></div>
                    </div>
                </div>
            </div>
        </van-list>
        <div style="width: 100%;height: 4rem;"></div>
        <float-nav></float-nav>
        <div class="fix-bottom">
            <van-button round type="danger" size="large" to="/addressEdit">新增地址</van-button>
        </div>
    </div>
</template>

<script>
  import { globalConfig, listLoading } from '../../utils/mixin'
  import FloatNav from '../../components/common/FloatNav'
  import { urlSetValue } from '../../utils/tools'

  export default {
    name: 'AddressList',
    components: { FloatNav },
    data () {
      return {
        isOrder: 0
      }
    },
    mixins: [globalConfig, listLoading],
    methods: {
      setDefault: function (id) {
        const that = this
        this.axios.post(this.apiUrl + 'mall/address/setDefault', {
          addressid: id
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status) {
            that.$toast({
              message: '设置成功',
              onClose: function () {
                that.$router.push('/refreshRouter')
              }
            })
          } else {
            that.$toast(data.msg)
          }
        })
      },
      delAddress: function (id) {
        const that = this
        this.$dialog.confirm({
          title: '提示',
          message: '确定删除该地址'
        }).then(() => {
          that.axios.post(this.apiUrl + 'mall/address/delete', {
            addressid: id
          }).then((response) => {
            const data = response.data
            console.log(data)
            if (data.status) {
              that.$toast({
                message: '删除成功',
                onClose: function () {
                  that.$router.push('/refreshRouter')
                }
              })
            }
          })
        }).catch(() => {})
      },
      editAddress: function (id) {
        this.$router.push('/addressEdit/' + id)
      },
      // 选择地址，和下单时候选择地址是向呼应的，只有从下单页面过来才可以点击
      selectAddress: function (id) {
        if (this.isOrder === 1) {
          // 当前正在下单，带着地址参数跳转回去原来的页面
          const url = urlSetValue(this.$store.state.lastOrderRoute, 'addressId', id)
          this.$router.push(url)
        }
      }
    },
    created () {
      this.isOrder = (this.$route.params.key === 'order') ? 1 : 0
      this.listLoadingInit(this.apiUrl + 'mall/init/site', 'list', 1, {})
    }
  }
</script>

<style scoped>
    .body {
        background: #f7f7f7;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .address-wrapper {
        width: 95vw;
        border-radius: 1vw;
        margin: 2.5vw 2.5vw;
        background: #fff;
        font-size: 1rem;
        color: #555;
    }
    .address-wrapper a {
        color: #555;
    }
    .address-user-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.36rem 1rem;
    }
    .address-info {
        padding: 0 1rem 0.5rem;
    }
    .address-bottom-wrapper {
        border-top: 1px solid #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }
    .address-bottom-wrapper .address-bottom-left-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .address-no-checked{
        outline: none;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        border: 1px solid #c9b2b2;
        margin:0 0.5rem 0 0.1rem;
    }
    .address-checked {
        outline: none;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background:url("https://seetop-1257860468.file.myqcloud.com/html/img/icon-checked.png");
        background-size: 1.2rem;
        margin:0 0.5rem 0 0.1rem;
    }
    .address-bottom-right-wrapper{
        display: flex;
        align-items: center;
    }
    .address-bottom-button {
        display: flex;
        align-items: center;
        margin:0 0.2rem 0 0.1rem;
        color: #555;
    }
    .address-bottom-button img {
        margin: 0 1.8vw;
        width: 3.6vw;
        height: 4vw;
    }
    .fix-bottom {
        position: fixed;
        width: 95%;
        left: 0;
        right: 0;
        bottom: 0.5rem;
        margin: 0 auto;
    }
</style>
